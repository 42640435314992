import React from 'react'
import { graphql } from 'gatsby'
import Layout  from '../components/layout';
import RichText from '../components/richtext';
import {PageBySlugQuery} from '../../graphql-types';
import { Helmet } from 'react-helmet';


const PageTemplate = (props) => {
    const graphqlData: PageBySlugQuery = props.data;
    const page = graphqlData.contentfulPage;

    return <>
        <Helmet title={page.title}/>
        <Layout slug={page.slug}>
            <RichText json={page.textbody.json} />
        </Layout>
    </>
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
  contentfulPage(slug: {eq: $slug}) {
    title
    slug
    textbody {
      json
    }
  }
}
`