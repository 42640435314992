import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {AnchorFinderQuery} from '../../graphql-types';
import { AnchorLink } from "gatsby-plugin-anchor-links";

interface targetNode {
    fields?: {
        name: {
            de: string;
        }
    };
    sys?: {
        contentType?: {
            sys?: {
                id?: string;
            }
        }
    };
}
interface contentNode {
    content?: contentNode[];
    nodeType?: string;
    data?: {
        target?: targetNode;
    };
}
const QualifiedAnchorLink = ({to, children}) => {
    const data: AnchorFinderQuery = useStaticQuery(graphql`
    query anchorFinder 
    {
      allContentfulPageTextbodyRichTextNode {
        nodes {
          content {
            nodeType
            content {
              nodeType
              content {
                nodeType
                content {
                  nodeType
                  content {
                    nodeType
                  }
                  data {
                    target {
                      fields {
                        name {
                          de
                        }
                      }
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
              data {
                target {
                  fields {
                    name {
                      de
                    }
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          parent {
            ... on ContentfulPage {
              slug
            }
          }
        }
      }
    }
  `)
    //return <pre>{JSON.stringify(data, null, 4)}</pre>
    const nodes = data.allContentfulPageTextbodyRichTextNode.nodes;
    let parentSlug: string = '';

    const digContent = (contentArray: contentNode[]): boolean => {
        for(const contentElement of contentArray) {
            if(contentElement.nodeType === 'embedded-entry-inline'
                && contentElement.data?.target?.sys?.contentType?.sys?.id === 'anchor'
                && contentElement.data?.target?.fields?.name.de === to) {
                return true;
            }
            if(contentElement.content) {
                if(digContent(contentElement.content)) {
                    return true;
                }
            }
        }
        return false
    }
    for(const node of nodes) {
        if(node.parent?.slug) {
            parentSlug = node.parent?.slug;
        }
        if(digContent(node.content as contentNode[])) {
            break;
        }
    }
    return <AnchorLink to={"/" + parentSlug + "#" + to}>{children}</AnchorLink>

}

export default QualifiedAnchorLink

